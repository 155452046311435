
import {
  computed,
  defineComponent,
  onActivated,
  onMounted,
  ref,
  watch,
} from "vue";

import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { UserLocation } from "@/store/modules/AuthModule";

export default defineComponent({
  name: "edit-user-locations-modal",
  components: {},
  props: ["account", "user"],

  setup(props) {
    const loading = ref<number>(0);
    const store = useStore();
    const userLocations = ref<UserLocation[]>([]);
    const initialized = ref<boolean>(false);

    const loadUserLocations = async () => {
      if (props.user.id !== undefined) {
        userLocations.value = [];
        loading.value++;
        console.log("loadingUserLocations + " + loading.value);

        try {
          let response = await ApiService.get(
            "api/v1/users/" + props.user.id + "/user_locations"
          );
          userLocations.value = response.data;
        } finally {
          loading.value--;
          initialized.value = true;
          console.log("loadingUserLocations - " + loading.value);
        }
      }
    };

    const enabledLocations = computed(() => {
      return store.state.AuthModule.locations.filter((loc) => {
        for (let i = 0; i < userLocations.value.length; i++) {
          if (
            userLocations.value[i].location ==
            "/api/v1/locations/" + loc.id
          ) {
            return true;
          }
        }
      });
    });

    const disabledLocations = computed(() => {
      return store.state.AuthModule.locations.filter((loc) => {
        let found = false;
        for (let i = 0; i < userLocations.value.length; i++) {
          if (
            userLocations.value[i].location ==
            "/api/v1/locations/" + loc.id
          ) {
            found = true;
          }
        }
        if (!found) {
          return true;
        }
      });
    });

    const disableLocation = async (location) => {
      loading.value++;
      console.log("disableLocation + " + loading.value);

      let deletedUL = userLocations.value.filter((ul) => {
        if (ul.location == "/api/v1/locations/" + location.id) {
          return true;
        }
        return false;
      });

      if (deletedUL.length > 0) {
        userLocations.value = userLocations.value.filter((ul) => {
          if (ul.location == "/api/v1/locations/" + location.id) {
            return false;
          }
          return true;
        });

        ApiService.delete("/api/v1/user_locations/" + deletedUL[0].id)
          .then()
          .finally(() => {
            loading.value--;
            console.log("disableLocation - " + loading.value);
          });
      }
    };

    const enableLocation = async (location) => {
      let ul = new UserLocation();
      ul.location = "/api/v1/locations/" + location.id;
      ul.user = "/api/v1/users/" + props.user.id;

      loading.value++;
      console.log("enableLocations + " + loading.value);
      delete ul.id;
      ApiService.post("api/v1/user_locations", ul)
        .then((response) => {
          ul.id = response.data.id;
          userLocations.value.push(ul);
        })
        .finally(() => {
          loading.value--;
          console.log("enableLocations - " + loading.value);
        });
    };

    onMounted(() => {
      loadUserLocations();
    });
    onActivated(() => {
      loadUserLocations();
    });

    watch(
      () => props.user,
      () => {
        store.dispatch(Actions.REFRESH_ACCOUNT_LOCATIONS);
        loadUserLocations();
      }
    );

    return {
      userLocations,
      loading,
      initialized,
      enabledLocations,
      disabledLocations,
      enableLocation,
      disableLocation,
    };
  },
});
