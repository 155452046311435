
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Location, User } from "@/store/modules/AuthModule";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import EditUserModal from "@/components/modals/forms/EditUserModal.vue";
import EditUserLocationsModal from "@/components/modals/forms/EditUserLocationsModal.vue";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "account-billing",
  components: { EditUserLocationsModal, EditUserModal },
  setup() {
    let l = {} as User;
    let empty = {} as User;
    const selectedUser = ref(l);
    const emptyUser = ref(empty);
    const store = useStore();

    const clearActiveUser = function () {
      let n = new User();
      Object.assign(selectedUser.value, n);
    };

    const loadUsers = function () {
      store.dispatch(Actions.REFRESH_ACCOUNT_USERS);
    };

    const setActiveUser = function (user) {
      selectedUser.value = user;
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Users", ["Account"]);
      store.dispatch(Actions.REFRESH_ACCOUNT_USERS);
    });
    return {
      selectedUser,
      clearActiveUser,
      setActiveUser,
      emptyUser,
      loadUsers,
    };
  },
});
